import ReactDOM from "react-dom";
import "./index.scss";
import "semantic-ui-css/semantic.min.css";
import {
  ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, createHttpLink,
} from "@apollo/client";
import { Provider as ReduxProvider } from "react-redux";
import { Provider as RollbarProvider } from "@rollbar/react";
import { setContext } from "@apollo/client/link/context";
import store from "./store";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";

const middlewareLink = setContext(() => ({
  headers: {
    "x-token": localStorage.getItem("token"),
    "x-refresh-token": localStorage.getItem("refreshToken"),
  },
}));

let gqlServerUri = null;
const port = process.env.PORT || 3001;
if (process.env.NODE_ENV === "production") {
  gqlServerUri = "/graphql";
} else {
  gqlServerUri = `http://localhost:${port}/graphql`;
}

const httpLink = createHttpLink({
  uri: gqlServerUri,
  credentials: "same-origin",
});

const afterwareLink = new ApolloLink((operation, forward) => {
  const { headers } = operation.getContext();
  if (headers) {
    const token = headers.get("x-token");
    const refreshToken = headers.get("x-refresh-token");

    if (token) {
      localStorage.setItem("token", token);
    }

    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    }
  }

  return forward(operation);
});

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: "1",
        guess_uncaught_frames: true,
      },
    },
  },
};

const link = afterwareLink.concat(middlewareLink.concat(httpLink));

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const WrappedApp = (
  <RollbarProvider config={rollbarConfig}>
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </ReduxProvider>
  </RollbarProvider>
);

// I'm not running react 18, but getting
// error  ReactDOM.render is deprecated since React 18.0.0, use createRoot instead,
// see https://reactjs.org/link/switch-to-createroot  react/no-deprecated
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(WrappedApp, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
