import { MouseEvent } from "react";
import styles from "./styles.module.scss";

interface Props {
  checked: boolean;
  disabled?: boolean;
  handleClick: () => void;
  label: string;
}

const Toggle = ({
  checked, handleClick, label, disabled,
}: Props) => (
  <div className={styles.toggle}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className="switch" htmlFor={label.toLowerCase()}>
      <input
        hidden
        disabled={disabled}
        type="checkbox"
        id={label.toLowerCase()}
        checked={checked}
        readOnly
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          handleClick();
          (e.target as HTMLInputElement).blur();
        }}
        onKeyDown={(e) => e.preventDefault()}
      />
      <span className={`slider round ${disabled ? "disabled" : null}`} />
    </label>
    <div className={`${styles.label} ${disabled ? styles.disabled : undefined}`}>
      {label}
    </div>
  </div>
);

Toggle.defaultProps = {
  disabled: false,
};

export default Toggle;
